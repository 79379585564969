/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import logo from "./logo.svg"
import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
            }}
          >
            <img
              src={logo}
              alt={'BobaDoge'}
              style={{
                height: 56,
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                width: 56,
                borderRadius: `50%`,
              }}
            />
            <p>
            Ada setidaknya 10 pasaran togel paling terkenal di Indonesia yang kini disediakan oleh beragam situs judi online di tanah air. Nah, untuk mengetahui pasaran togel apa saja yang terdapat di luar negeri, maka silakan cek rincian lengkapnya di bawah ini.
            </p>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioEnQuery {
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

export default Bio
