import { rhythm, scale } from "../utils/typography"

import Bio from "../components/bio"
import BioEn from "../components/en/bio"
import { Link } from "gatsby"
import React from "react"

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let header
    const language = location.pathname.indexOf('/en/') === -1 ? 'es' : 'en'

    const activeStyle = { boxShadow: "none", fontWeight: 'bold', color: "hsla(0,0%,0%,0.7)" };
    const submenu = <div>
      <Link
        to={language === 'en' ? '/en/' : '/'}
        activeStyle={activeStyle}
      >Home
      </Link>{' '}
      &bull;{' '}
      <Link
        to={language === 'en' ? '/en/blog/' : '/blog/'}
        activeStyle={activeStyle}>
        Blog
      </Link>{' '}
      &bull;{' '}
      <Link
        to="/link/"
        activeStyle={activeStyle}
      >{language === 'en' ? 'Link Alternatif' : 'Link Alternatif'}
      </Link>{' '}
      &bull;{' '}
      <Link
        to={language === 'en' ? '/en/' : '/tentang-kami'}
        activeStyle={activeStyle}
      >Tentang
      </Link>{' '}
      
    </div>;

    if (location.pathname === rootPath) {
      header = (
        <span
          style={{
            ...scale(1),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            TheBodaDoge
          </Link>
        </span>
      )
    } else {
      header = (
        <span
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            TheBodaDoge
          </Link>
        </span>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        {language === 'en' ? <BioEn /> : <Bio />}
        {submenu}
        <main>{children}</main>
        {submenu}
      </div>
    )
  }
}

export default Layout
